// L'URL de l'API peut être modifiée dans le fichier public/config.js
export const API_URL = window.config.apiUrl;

const KEY = "API_TOKEN";

export const NAV_APP_CODE = "CWA";

export const setToken = (token) => localStorage.setItem(KEY, token);

export const getToken = () => localStorage.getItem(KEY);

export const isLoggedIn = () => {
	const token = getToken();
	if (token !== "" && token !== null && token !== undefined && parseJwt(getToken()).AccAppCode === NAV_APP_CODE) return true;
	else {
		removeToken();
		return false;
	}
};

export const removeToken = () => localStorage.removeItem(KEY);

export const parseJwt = (token) => {
	if (getToken()) {
		var base64Url = token.split(".")[1];
		var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
		var jsonPayload = decodeURIComponent(
			window
				.atob(base64)
				.split("")
				.map(function (c) {
					return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
				})
				.join("")
		);
	}
	return JSON.parse(jsonPayload);
};

export const getAccLinkNo = () => parseJwt(getToken())?.AccLinkNo;
