import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import React from "react";
import { useTranslation } from "react-i18next";

// #region Gestion du LocalStorage
const SAVE_ON_BLUR = "SAVE_ON_BLUR";

export const getSaveOnBlur = () => {
	let storage = localStorage.getItem(SAVE_ON_BLUR);

	if (storage === null) return false;
	else return storage === "true";
};
// #endregion Gestion du LocalStorage

export function useSaveOnBlur() {
	return React.useState(getSaveOnBlur());
}

export default function ToggleSaveOnBlur() {
	const { t } = useTranslation();

	const [saveOnBlur, setSaveOnBlur] = useSaveOnBlur();

	React.useEffect(() => {
		localStorage.setItem(SAVE_ON_BLUR, saveOnBlur);
	}, [saveOnBlur]);

	return (
		<FormControlLabel
			control={<Switch checked={saveOnBlur} onClick={() => setSaveOnBlur((saveOnBlur) => !saveOnBlur)} />}
			label={t("translation:toggleSaveOnBlur")}
		/>
	);
}
