import { AppBar, Button, Grid, Menu, MenuItem, Toolbar } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { AccountCircle, ChevronLeft, Menu as MenuIcon } from "@material-ui/icons";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useHistory, withRouter } from "react-router-dom";
import styled from "styled-components";
import { showTranslations } from "translation-check";
import { removeToken } from "../../api";
import useFetch from "../../api/fetcher";
import { getDevMode } from "../../api/localStorage";
import { IconButton } from "../../pages/components";
import ToggleSaveOnBlur from "../../pages/components/ToggleSaveOnBlur";
import SupportDialog from "../../pages/dialogs/SupportDialog";

// #region Customisation de certains éléments
const Flag = styled.img`
	border-radius: 50%;
	width: 22px;
	height: 22px;
`;

const AccountIcon = styled(AccountCircle)`
	height: 28px;
	width: 28px;
`;

const Spacer = styled.div`
	flex: 1 1 20%;
`;

const useStyles = makeStyles((theme) => ({
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		width: `calc(100% - ${theme.sidebar.width}px)`,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		[theme.breakpoints.down("sm")]: {
			width: "100%",
		},
	},
	drawer: {
		width: theme.sidebar.width,
		flexShrink: 0,
		whiteSpace: "nowrap",
	},
	toolbar: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
	},
	logo: {
		maxWidth: "250px",
		maxHeight: "55px",
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
}));
// #endregion Customisation de certains éléments

function LanguageMenu() {
	const { t, i18n } = useTranslation();

	const [anchorMenu, setAnchorMenu] = useState(null);

	const toggleMenu = (event) => {
		if (event.ctrlKey && getDevMode()) return showTranslations(i18n);

		setAnchorMenu(event.currentTarget);
	};

	const setLanguage = (lng) => {
		// On évite d'appeler la fonction si ce n'est pas nécessaire
		if (i18n.language !== lng) {
			i18n.changeLanguage(lng);
		}
		// Quoi qu'il arrive, on ferme le menu
		closeMenu();
	};

	const closeMenu = () => {
		setAnchorMenu(null);
	};

	return (
		<MenuItem>
			<Button
				color="inherit"
				onClick={toggleMenu}
				endIcon={<Flag src={t("translation:lngFlag")} alt={t("translation:lngName")} />}
				style={{ padding: 0, fontSize: "16px", fontWeight: 400 }} // Copie du style des autre boutons du menu
			>
				{t("translation:chooseLanguage")}
			</Button>
			<Menu id="menu-appbar" anchorEl={anchorMenu} open={Boolean(anchorMenu)} onClose={closeMenu}>
				<MenuItem onClick={() => setLanguage("en")}>{t("translation:english")}</MenuItem>
				<MenuItem onClick={() => setLanguage("fr")}>{t("translation:french")}</MenuItem>
			</Menu>
		</MenuItem>
	);
}

const UserMenu = withRouter(() => {
	const [anchorMenu, setAnchorMenu] = useState(null);
	const { data: user } = useFetch("GET", "/login/identity");

	const history = useHistory();

	const { t } = useTranslation();

	const toggleMenu = (event) => {
		setAnchorMenu(event.currentTarget);
	};

	const closeMenu = () => {
		setAnchorMenu(null);
	};

	const logout = () => {
		removeToken();
		history.push("/auth/login");
	};

	var username = user?.AccFirstName || user?.AccLogin;
	if (!username) {
		username = <Skeleton variant="text" width={40} />;
	}

	return (
		<>
			<SupportDialog />

			<Button color="inherit" onClick={toggleMenu} startIcon={<AccountIcon />}>
				{username}
			</Button>
			<Menu open={Boolean(anchorMenu)} anchorEl={anchorMenu} onClose={closeMenu}>
				<LanguageMenu />
				{/* <MenuItem component={RouterLink} to="/company-information">
					{t("translation:companyInformation")}
				</MenuItem> */}
				<MenuItem component={RouterLink} to="/contractor">
					{t("profile")}
				</MenuItem>
				<MenuItem>
					<ToggleSaveOnBlur />
				</MenuItem>
				{/* <MenuItem component={RouterLink} to="/profil">
					{t("documents")}
				</MenuItem> */}
				{/* #region Spé [~] AWA | [x] CWA - Paramètres
				 <MenuItem component={RouterLink} to="/settings">
					{t("settings")}
				</MenuItem>
				 #endregion Spé [~] AWA | [x] CWA - Paramètres */}
				<MenuItem onClick={logout} style={{ backgroundColor: red[700], color: "#EEE" }}>
					{t("translation:disconnect")}
				</MenuItem>
			</Menu>
		</>
	);
});

export default function Header({ open, setOpen }) {
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<AppBar
			position="fixed"
			className={clsx(classes.appBar, {
				[classes.appBarShift]: open,
			})}
		>
			<Toolbar>
				<IconButton
					color="inherit"
					aria-label="open drawer"
					onClick={() => setOpen(!open)}
					edge="start"
					icon={open ? <ChevronLeft /> : <MenuIcon />}
					title={t(`translation:${open ? "close" : "open"}Drawer`)}
				/>

				<Grid container alignItems="center">
					<Spacer />
					{/* Affichage du filtre porté */}
					{/* <Grid item xs container alignItems="center">
						<ContractorSearchForFilter open={openContractorSearch} setOpen={setOpenContractorSearch} />
						{(() => {
							if (contractorFilter === undefined) {
								return (
									<Grid item>
										<Skeleton animation="wave" width="200px" height="30px" />
									</Grid>
								);
							} else if (contractorFilter === null) {
								return (
									<>
										<ButtonGroup variant="text" style={{ color: "inherit" }}>
											<IconButton
												style={{ color: "inherit", border: "none" }}
												onClick={() => setOpenContractorSearch(true)}
												icon={<WarningOutlinedIcon />}
												title={t("translation:noContractorFilter")}
											/>
											<Button style={{ color: "inherit", border: "none" }} onClick={() => setOpenContractorSearch(true)}>
												<Typography variant="h6">{t("translation:noContractorFilter")}</Typography>
											</Button>
										</ButtonGroup>
									</>
								);
							} else {
								return (
									<ButtonGroup variant="text" style={{ color: "inherit" }}>
										<IconButton
											style={{ color: "inherit", border: "none" }}
											onClick={() => setOpenContractorSearch(true)}
											icon={<FilterList />}
											title={t("translation:contractorFilterIcon")}
										/>
										<Button style={{ color: "inherit", border: "none" }} onClick={() => setOpenContractorSearch(true)}>
											<Typography variant="h6">{contractorFilter.FirstName + " " + contractorFilter.LastName}</Typography>
										</Button>
										<LoadingIconButton
											style={{ color: "inherit", border: "none", borderRadius: "0 50% 50% 0" }}
											title={t("translation:deleteContractorFilter")}
											loading={loading}
											onClick={deleteContractorFilter}
											icon={<DeleteIcon />}
										/>
									</ButtonGroup>
								);
							}
						})()}
					</Grid> */}

					{/* Menu de profil */}
					<Grid item>
						<UserMenu />
					</Grid>
				</Grid>
			</Toolbar>
		</AppBar>
	);
}
