import * as types from "../constants";

const INITIAL_STATE = { customisedMenu: [
	{Code: "WORK-CONTRACTS", Activated: true},
	{Code: "ACTIVITY-REPORT", Activated: true},
	{Code: "INV-REQUESTS", Activated: true},
	{Code: "IMPORTED-WAGES", Activated: true},
	{Code: "CONTACTS", Activated: true},
	{Code: "COM-AGREEMENTS", Activated: true},
	{Code: "CHARGES", Activated: true},
	{Code: "INVOICES", Activated: true},
	{Code: "ACTIVITY-ACCOUNT", Activated: true},
	{Code: "CUSTOMERS", Activated: true},
	{Code: "WAGES", Activated: true},
] };

export default function reducer(state = INITIAL_STATE, actions) {
	switch (actions.type) {
		case types.SET_CUSTOMISED_MENU:
			return {
				...state,
				customisedMenu: actions.payload,
			};

		case types.CLEAR_CUSTOMISED_MENU:
			return { customisedMenu: null };

		default:
			return state;
	}
}
