import { combineReducers } from "redux";

import contractorFilterReducer from "./contractorFilterReducers";
import customisedMenu from './customisedMenuReducers';
import requestHistoryReducer from "./requestHistoryReducer";
import selectedContractorReducer from "./selectedContractorReducers";
import themeReducer from "./themeReducers";

export default combineReducers({
	themeReducer,
	contractorFilterReducer,
	requestHistoryReducer,
	selectedContractorReducer,
	customisedMenu
});
